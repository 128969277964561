// @flow
/**
 * Helper class
 * @module BulkUploadUtils
 */
 import { lstatSync } from "fs";
import { async } from "regenerator-runtime";



 class BulkUploadUtils {
    static ClientSharedValues = {
        lstatSync: () => {},
      };

    static ClientDefaultValues = {
        ...this.ClientSharedValues,
        runtime: "node",
        lstatSync,
      };

     static byteLength = (input) => {
        if (input === null || input === undefined) return 0;
            if (typeof input === "string") input = Buffer.from(input);
            if (typeof input.byteLength === "number") {
                return input.byteLength;
            } else if (typeof input.length === "number") {
                return input.length;
            } else if (typeof input.size === "number") {
                return input.size;
            } else if (typeof input.path === "string") {
                try {
                return this.ClientDefaultValues.lstatSync(input.path).size;
                } catch (error) {
                return undefined;
                }
            }
            return undefined;
    }


    static getChunk = (data, partSize) => {
        if (data) {
          return getChunkStream((data).stream(), partSize, getDataReadableStream);
        } else if (data) {
          return getChunkStream((data).stream(), partSize, getDataReadableStream);;
        } else if (data  || typeof data === "string" || data ) {
          // chunk Strings, Uint8Array.
          return getChunkStream((data).stream(), partSize, getDataReadableStream);;
        }
        if (typeof (data).stream === "function") {
          // approximate support for Blobs.
          return getChunkStream((data).stream(), partSize, getDataReadableStream);
        } else if (data ) {
          return getChunkStream(data, partSize, getDataReadableStream);
        } else {
          throw new Error(
            "Body Data is unsupported format, expected data to be one of: string | Uint8Array | Buffer | Readable | ReadableStream | Blob;."
          );
        }
      };  
 }
 
 export default BulkUploadUtils


 export async function* getChunkStream(
  data,
  partSize,
  getNextData
) {
  let partNumber = 1;
  const currentBuffer= { chunks: [], length: 0 };

  for await (const datum of getNextData(data)) {
    currentBuffer.chunks.push(datum);
    currentBuffer.length += datum.length;

    while (currentBuffer.length > partSize) {
      /**
       * Concat all the buffers together once if there is more than one to concat. Attempt
       * to minimize concats as Buffer.Concat is an extremely expensive operation.
       */
      const dataChunk = currentBuffer.chunks.length > 1 ? Buffer.concat(currentBuffer.chunks) : currentBuffer.chunks[0];

      yield {
        partNumber,
        data: dataChunk.slice(0, partSize),
      };

      // Reset the buffer.
      currentBuffer.chunks = [dataChunk.slice(partSize)];
      currentBuffer.length = currentBuffer.chunks[0].length;
      partNumber += 1;
    }
  }
  yield {
    partNumber,
    data: Buffer.concat(currentBuffer.chunks),
    lastPart: true,
  };
}
 

export async function* getDataReadableStream(data){
  // Get a lock on the stream.
  const reader = data.getReader();

  try {
    while (true) {
      // Read from the stream.
      const { done, value } = await reader.read();
      // Exit if we're done.
      if (done) return;
      // Else yield the chunk.
      yield Buffer.from(value);
    }
  } catch (e) {
    throw e;
  } finally {
    // release the lock for reading from this stream.
    reader.releaseLock();
  }
}
