import { history } from 'dsilo-ui-components';
import _ from 'modules/lodash';
import { store } from 'store';
import jwtService from 'jwtService';
import { ActionTypes } from 'constants/index';

/**
 * Set User Data
 */
export function setUserData(user) {
    return (dispatch) => {
        /*
        Set User Data
         */
        dispatch({
            type: ActionTypes.SET_USER_DATA,
            payload: user
        })
    }
}

/**
 * Update User Settings
 */
export function updateUserSettings(settings) {
    return (dispatch, getState) => {
        const oldUser = getState().auth.user;
        const user = _.merge({}, oldUser, { data: { settings } });

        updateUserData(user);

        return dispatch(setUserData(user));
    }
}

/**
 * Update User Shortcuts
 */
export function updateUserShortcuts(shortcuts) {
    return (dispatch, getState) => {
        const user = getState().auth.user;
        const newUser = {
            ...user,
            data: {
                ...user.data,
                shortcuts
            }
        };

        updateUserData(newUser);

        return dispatch(setUserData(newUser));
    }
}

/**
 * Remove User Data
 */
export function removeUserData() {
    return {
        type: ActionTypes.REMOVE_USER_DATA
    }
}

/**
 * Logout
 */
export function logoutUser() {
    return (dispatch, getState) => {
        let user;
        if (getState().auth)
            user = getState().auth.user;

        if (user && user.role === 'guest') {
            return null;
        }

        jwtService.logout();

        dispatch({
            type: ActionTypes.USER_LOGGED_OUT
        })

        dispatch({
            type: ActionTypes.LOGIN_ERROR
        })


        // dispatch(setDefaultSettings(FuseDefaultSettings));

    }
}

export function ssoExpire () {
    dispatch({
        type: ActionTypes.USER_SSO_EXPIRE
    })
}

/**
 * Update User Data
 */
function updateUserData(user) {
    if (user.role === 'guest') {
        return;
    }

    jwtService.updateUserData(user)
        .then(() => {
            store.dispatch(Actions.showMessage({ message: "User data saved with api" }));
        })
        .catch(error => {
            store.dispatch(Actions.showMessage({ message: error.message }));
        });
}



export const setUserDetailsLoading = (data) => dispatch => {
    dispatch({ type: ActionTypes.SET_USER_DETAILS_LOADING, payload: data });
};