import axios from 'axios';
import jwtDecode from 'jwt-decode';
import EventEmitter from './EventEmitter';
import { apiBaseAddress } from 'modules/api/index';
import { history } from 'dsilo-ui-components';
import { Request } from "../modules/api/clientHelper";
import { logoutRedirect } from '../Pages/Login/msalService';

const BASE_URL = process.env.REACT_APP_API_BASE_URL;
const BASE_URL_CREATE = process.env.REACT_APP_API_BASE_URL_CREATE;
import { PUBLIC_PATH } from 'constants/index'
let timeout;

class jwtService extends EventEmitter {

	init() {
		this.setInterceptors();
		this.handleAuthentication();
	}

	setInterceptors = () => {
		axios.interceptors.response.use(
			response => {
				return response;
			},
			err => {
				return new Promise((resolve, reject) => {
					if (err.response?.status === 401 && err.config && !err.config.__isRetryRequest) {
						// if you ever get an unauthorized response, logout the user
						this.emit('onAutoLogout', 'Invalid access_token');
						this.setSession(null);
						clearInterval(timeout);
						timeout = null;
					}
					throw err;
				});
			},
		);
	};

	handleAuthentication = () => {
		let access_token = this.getAccessToken();

		if (!access_token) {
			return;
		}

		if (this.isAuthTokenValid(access_token)) {
			this.setSession(access_token);
			this.emit('onAutoLogin', true);
		} else {
			this.setSession(null);
			clearInterval(timeout);
			timeout = null;
			this.emit('onAutoLogout', 'access_token expired');
		}
	};

	createUser = data => {
		return new Promise((resolve, reject) => {
			Request(BASE_URL + '/user/register', {
				method: 'POST',
				payload: {
					...data
				}
			})
				.then(response => {
					console.log('respon', response);
					const { data } = response.data;
					resolve(data);
				})
				.catch(err => {
					console.error('err ==> ', err);
					reject(err.response.message);
				});
		});
	};

	verifyEmail = data => {
		const { token } = data;
		return Request(BASE_URL + '/user/verifyEmail', {
			method: 'GET',
			headers: {
				Authorization: `${token}`,
				'content-type': 'application/json'
			},
		})
	};

	checkEmailForAad = (payload) => {
		return new Promise((resolve, reject) => {
			Request(BASE_URL + '/user/emailcheck', {
				method: 'POST',
				payload
			}).then(response => {
				const { data } = response;
				resolve({ data: data });
			}).catch(err => {
				console.error('err ==> ', err);
				reject(err?.response);
			});
		});
	};

	aadLogin = (data) => {
		return new Promise((resolve, reject) => {
			const { orgId, accessToken } = data;
			Request(BASE_URL + '/aadlogin', {
				method: 'GET',
				headers: {
					Authorization: `${accessToken}`,
					orgId,
					'content-type': 'application/json'
				},
			}).then(response => {
				const { data } = response;
				// if (data?.refreshToken) {
				// 	timeout = setInterval(() => {
				// 		this.refreshToken();
				// 	}, 9 * 60000);
				// }
				resolve({ data: data });
			}).catch(err => {
				console.error('err ==> ', err);
				reject(err?.response);
			});
		});
	};

	// This is used to send the SAMLResponse to platform API to validate and sent the token details.
	validateSAMLResponse = (samlData) => {
		console.log(' samlData ::: *** ', samlData);
		return new Promise((resolve, reject) => {
			Request(BASE_URL + '/saml/acs/internal', {
				method: 'POST',
				headers: {
					'content-type': 'application/json'
				},
				payload: {
					SAMLResponse: samlData
				}
			}).then(response => {
				const { data } = response;
				// if (data?.refreshToken) {
				// 	timeout = setInterval(() => {
				// 		this.refreshToken();
				// 	}, 9 * 60000);
				// }
				resolve({ data: data });
			}).catch(err => {
				console.error('err ==> ', err);
				reject(err?.response);
			});
		});
	};

	signInWithEmailAndPassword = ({ userconfig }) => {
		return new Promise((resolve, reject) => {
			Request(BASE_URL + '/user/login', {
				method: 'POST',
				payload: {
					userconfig
				}
			})
				.then(response => {
					console.log("response ====", response);
					const { data } = response;
					this.setSession(data.token);
					// localStorage.setItem('refreshToken',data.refreshToken)
					// this would reset token expiry time
					timeout = setInterval(() => {
						this.refreshToken();
					}, 9 * 60000);
					resolve({ data: data, setSession: this.setSession });
				})
				.catch(err => {
					console.error('err ==> ', err);
					reject(err?.response);
				});
		});
	};

	refreshToken = () => {
		return new Promise((resolve, reject) => {
			// let token = localStorage.getItem('refreshToken')
			let token = localStorage.getItem('jwt_access_token')
			Request(BASE_URL + `/user/refreshToken`, {
				method: 'POST',
				payload: {},
				headers: {
					Authorization: `${token}`,
				}
			})
				.then(response => {
					const { data } = response;
					this.setSession(data.token);
					resolve(response.data);
				})
				.catch(err => {
					console.error('err ==> ', err);
					this.setSession(null);
					clearInterval(timeout);
					timeout = null;
					history.push({
						pathname: `/${PUBLIC_PATH}login`
					});
					reject(err.response);
				});
		});
	};

	resetPassword = data => {
		return new Promise((resolve, reject) => {
			Request(BASE_URL + `/user/resetpwd`, {
				method: 'POST',
				payload: {
					...data,
				},
				headers: {
					Authorization: `${data.token}`,
				}
			})
				.then(response => {
					resolve(response.data);
				})
				.catch(err => {
					console.error('err ==> ', err);
					reject(err.response);
				});
		});
	};

	verifyUser = data => {
		console.log("data ===", data);
		const { token } = data;
		return Request(BASE_URL + `/user/verifyuser`, {
			headers: {
				Authorization: `${token}`,
				'content-type': 'application/json'
			},
			method: 'GET',
		})
	};

	signInWithToken = () => {
		console.log("signInWithToken ==")
		return new Promise((resolve, reject) => {
			let accessToken = this.getAccessToken();
			this.setSession(accessToken);
			if (this.isAuthTokenValid(accessToken)) {
				Request(BASE_URL + `/user/userdetails`, {
					method: 'GET',
				})
					.then(response => {
						const userDetails = response?.data;
						if (userDetails) {
							let user = {
								isAuthenticated: true,
								id: userDetails._id,
								userName: userDetails.userName,
								roles: userDetails.roles,
								orgId: userDetails.orgId,
								email: userDetails.email,
								subscription: userDetails.subscription,
								...userDetails,
							};
							//Clear existing interval
							clearInterval(timeout);
							timeout = null;
							// if settimeout is cleared, due to hard refresh or another scenario
							// this would restart the refresh token request
							const isAdLogin = localStorage.getItem('isAdLogin') || '';
							const isSsoLogin = localStorage.getItem('isSsoLogin') || '';
							// Don't call refresh token in both scenarios
							if (!(isAdLogin !== 'true' || isSsoLogin !== 'true')) {
								timeout = setInterval(() => {
									this.refreshToken();
								}, 9 * 60000);

								// LOCAL: Comment `this.refreshToken();` and `}, 9 * 60000);` for local 
								// LOCAL: Uncomment }, 9 * 6000000) for local
								// This invoke will happen after refresh, setInterval only executes after 9 seconds
								this.refreshToken();
								// }, 9 * 6000000);
							}
							resolve(user);
						} else {
							reject(response?.data);
						}
					})
					.catch(err => {
						console.error('err ==> ', err);
						reject(err.response);
					});
			} else {
				reject('error');
			}
		});
	};

	updateUserData = user => {
		return Request(apiBaseAddress() + '/api/auth/user/update', {
			method: 'POST',
			payload: {
				user: user,
			}
		});
	};

	setSession = (access_token, type = undefined) => {
		if (type === "mfa") {

		} else if (type === "sso") {

		} else {
			if (access_token) {
				localStorage.setItem('jwt_access_token', access_token);
				axios.defaults.headers.common['Authorization'] = access_token;
			} else {
				localStorage.removeItem('jwt_access_token');
				localStorage.removeItem('jwtuserData');
				localStorage.removeItem('userSession');
				localStorage.removeItem('refreshToken')
				delete axios.defaults.headers.common['Authorization'];
			}
		}
	};

	removeAdSsoConfig = () => {
		localStorage.removeItem('isAdLogin');
		localStorage.removeItem('isSsoLogin');
		localStorage.removeItem('ssoConfig');
		localStorage.removeItem('adConfig');
	}

	logout = async () => {
		let token = localStorage.getItem('jwt_access_token');
		const isAdLogin = localStorage.getItem('isAdLogin') || '';
		if (token) {
			if (isAdLogin === 'true') {
				const rawMsalAdConfig = localStorage.getItem('adConfig');
				if (rawMsalAdConfig) {
					const msalAdConfig = JSON.parse(window.atob(rawMsalAdConfig));
					console.log('from session storage ::: *** ', msalAdConfig)
					this.setSession(null);
					this.removeAdSsoConfig();
					const logoutResponse = await logoutRedirect(msalAdConfig);
					console.log('result ::: *** ', logoutResponse);
				} else {
					// TODO: For SSO we need to implement the logout functinality.
					this.setSession(null);
					this.removeAdSsoConfig();
					history.push({
						pathname: `/${PUBLIC_PATH}login`
					});
				}
			} else {
				return new Promise((resolve, reject) => {
					Request(BASE_URL + `/user/logout`, {
						method: 'POST',
						payload: {},
					})
						.then(response => {
							console.log('response ===????', response);
							this.setSession(null);
							this.removeAdSsoConfig();
							clearInterval(timeout);
							timeout = null;
							history.push({
								pathname: `/${PUBLIC_PATH}login`
							});
							if (response.data) {
								resolve(response.data);
							} else {
								reject(response.data.message);
							}
						})
						.catch(err => {
							console.log('err ===????', err);
							this.setSession(null);
							clearInterval(timeout);
							timeout = null;
							reject(err.response);
						});
				});
			}
		} else {
			history.push({
				pathname: `/${PUBLIC_PATH}login`
			});
		}
	};

	isAuthTokenValid = access_token => {
		if (!access_token) {
			return false;
		}
		try {
			const decoded = jwtDecode(access_token);
			const currentTime = Date.now() / 1000;
			if (decoded.exp < currentTime) {
				console.warn('access token expired');
				return false;
			} else {
				return true;
			}
		} catch (e) {
			return false;
		}

	};

	getRefreshToken = () => {
		return window.localStorage.getItem('refreshToken');
	};

	getAccessToken = () => {
		return window.localStorage.getItem('jwt_access_token');
	};
}

const instance = new jwtService();

export default instance;
