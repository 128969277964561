/**
 * Client
 * @module Client
 */

import { logoutUser, ssoExpire } from '../store/actions';

export class ServerError extends Error {
	constructor(message) {
		super(message);

		Error.captureStackTrace(this, ServerError);

		this.name = 'ServerError';

		return this;
	}
}

export function parseError(error) {
	return error || 'Something went wrong';
}

/**
 * Fetch data
 *
 * @param {string} url
 * @param {Object} options
 * @param {string} [options.method] - Request method ( GET, POST, PUT, ... ).
 * @param {string} [options.payload] - Request body.
 * @param {Object} [options.headers]
 *
 * @returns {Promise}
 */
export function request(url, options = {}) {
	const config = {
		method: 'GET',
		...options,
	};
	const errors = [];

	if (!url) {
		errors.push('url');
	}

	if (!config.payload && config.method !== 'GET' && config.method !== 'DELETE') {
		errors.push('payload');
	}

	if (errors.length) {
		throw new Error(`Error! You must pass \`${errors.join('`, `')}\``);
	}

	const headers = {
		Accept: 'application/json',
		'Content-Type': 'application/json',
		...config.headers,
		Authorization: localStorage.getItem('jwt_access_token') || '',
	};

	const params = {
		headers,
		method: config.method,
	};

	if (params.method !== 'GET') {
		params.body = JSON.stringify(config.payload);
	}

	return fetch(url, params).then(async response => {
		const contentType = response.headers.get('content-type');
		console.log("response.status", response.status);
		if (response.status > 299) {
			if (response.status === 401) {
				// For SSO, on 401 validate against SSO session
				let isSsoLogin = localStorage.getItem('isSsoLogin')
				console.log("isSsoLogin ????", isSsoLogin)
				if (isSsoLogin) {
					toast.info("Please try again, due to inactivity session is being restarted", { toastId: 'restart-session' })
					store.dispatch(ssoExpire());
				} else {
					console.log('session expired, login again')
					store.dispatch(logoutUser());
				}
			} else {
				const error = new ServerError(response.statusText);
				error.status = response.status;

				if (contentType && contentType.includes('application/json')) {
					error.response = await response.json();
				} else {
					error.response = await response.text();
				}

				throw error.response;
			}
		} else {
			if (contentType && contentType.includes('application/json')) {
				return response.json();
			} else if (contentType && contentType.includes('application/pdf')) {
				return response.blob()
			} else if (contentType && contentType.includes('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
				return response.blob()
			}

			return response.text();
		}
	});
}
