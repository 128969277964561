import { EventEmitter } from "events";
import BulkUploadUtils from "../../../utility/BulkUploadUtils";
import Utils from 'packages/page/utils';

const MIN_PART_SIZE = 1024 * 1024 * 5;

export class Upload extends EventEmitter {
	MAX_PARTS = 10000;

	// Defaults.
	queueSize = 4;
	partSize = MIN_PART_SIZE;
	leavePartsOnError = false;
	tags = [];

	params;

	// used for reporting progress.
	totalBytes;
	bytesUploadedSoFar;

	// used in the upload.
	concurrentUploaders = [];
	createMultiPartPromise;

	uploadedParts = [];
	uploadId;
	uploadEvent;
	totalPartsEvent;

	isMultiPart = true;
	putResponse;

	constructor(options, { bulkUploadPDF, bulkUploadPDFFinal, uploadPDF, handleUploadResult, handleFileUploadProgess }, appId, data) {
		super();

		// set defaults from options.
		this.queueSize = options.queueSize || this.queueSize;
		this.partSize = options.partSize || this.partSize;
		this.leavePartsOnError = options.leavePartsOnError || this.leavePartsOnError;
		this.tags = options.tags || this.tags;

		this.client = options.client;
		this.params = options.params;
		this.bulkUploadAPi = bulkUploadPDF
		this.bulkUploadPDFFinal = bulkUploadPDFFinal
		this.uploadPDF = uploadPDF
		this.validateInput();

		this.handleUploadResult = handleUploadResult ? handleUploadResult.bind(this) : null;
		this.handleFileUploadProgess = handleFileUploadProgess ? handleFileUploadProgess.bind(this) : null;

		// Bind functions
		this.triggerUploadResult = this.triggerUploadResult.bind(this);
		this.triggerFileUploadProgress = this.triggerFileUploadProgress.bind(this);

		// set progress defaults
		this.totalBytes = BulkUploadUtils.byteLength(this.params.body);
		this.bytesUploadedSoFar = 0;
		this.appId = appId
		this.data = data
		this.isDataModelEmpty = data?.chartData?.isDataModelEmpty || false;
	}

	async init() {
		console.log('init')
		return await Promise.race([this.doMultipartUpload()]);
	}

	async doMultipartUpload() {
		console.log('doMultipartUpload')
		// Set up data input chunks.
		const dataFeeder = BulkUploadUtils.getChunk(this.params.body, this.partSize);

		// Create and start concurrent uploads.
		for (let index = 0; index < this.queueSize; index++) {
			const currentUpload = this.doConcurrentUpload(dataFeeder);
			this.concurrentUploaders.push(currentUpload);
		}

		console.log('this.concurrentUploaders', this.concurrentUploaders)

		// Create and start concurrent uploads.
		await Promise.all(this.concurrentUploaders);

		return this.handleAfterUpload()
	}

	async doConcurrentUpload(dataFeeder) {
		console.log('doConcurrentUpload')
		for await (const dataPart of dataFeeder) {
			if (this.uploadedParts.length > this.MAX_PARTS) {
				throw new Error(
					`Exceeded ${this.MAX_PARTS} as part of the upload to ${this.params.key} and ${this.params.Bucket}.`
				);
			}
			try {
				// Use put instead of multi-part for one chunk uploads.
				if (dataPart.partNumber === 1 && dataPart.lastPart) {
					return await this.uploadUsingPut(dataPart);
				}
				if (!this.uploadId) {
					await this.createMultipartUpload();
				}

				dataPart.index = dataPart.partNumber
				dataPart.total = this.params.total
				dataPart.size = this.params.size
				dataPart.hash = this.params.hash
				dataPart.name = this.params.name

				dataPart.uniqId = this.uploadId
				dataPart.appId = this.appId
				dataPart.dataModelId = this.isDataModelEmpty ? "noDmId" : this.data.chartData.selectedDataModel

				try {
					dataPart.data = new Blob([dataPart.data])
				} catch (error) {
					console.log("error  ==== ? ", error);
				}

				let totalPartNumber = null

				if (dataPart.lastPart) {
					totalPartNumber = dataPart.partNumber
				}
				if (totalPartNumber) {
					this.totalParts({
						'totalPartNumber': totalPartNumber,
						'name': dataPart.name
					})
				}

				//TODO: - data(dataModel,Id)
				await this.bulkUploadAPi(dataPart)

				this.bytesUploadedSoFar += BulkUploadUtils.byteLength(dataPart.data);
				this.notifyProgress({
					loaded: this.bytesUploadedSoFar,
					total: this.totalBytes,
					part: dataPart.partNumber,
					Key: this.params.key,
					Bucket: this.params.Bucket,
					"TotalParts": totalPartNumber
				});
			} catch (e) {
				// Failed to create multi-part or put
				if (!this.uploadId) {
					throw e;
				}
				// on leavePartsOnError throw an error so users can deal with it themselves,
				// otherwise swallow the error.
				if (this.leavePartsOnError) {
					throw e;
				}
			}
		}
	}

	triggerUploadResult = (index, success) => {
		if (this.handleUploadResult) {
			this.handleUploadResult(index, success);
		} else {
			console.log("handleUploadResult is not defined.");
		}
	}

	triggerFileUploadProgress = (progress) => {
		if (this.handleFileUploadProgess) {
			this.handleFileUploadProgess(progress);
		} else {
			console.log("handleFileUploadProgess is not defined.");
		}
	}

	async handleAfterUpload() {
		console.log("handleAfterUpload")
		let result;
		if (this.isMultiPart) {
			this.uploadedParts.sort((a, b) => a.PartNumber - b.PartNumber);
			const data = {

			};
			const uploadCompleteParams = {
				...this.params,
				Body: undefined,
				UploadId: this.uploadId,
				MultipartUpload: {
					Parts: this.uploadedParts,
				},
				uniqId: this.uploadId,
				appId: this.appId,
				dataModelId: this.isDataModelEmpty ? "noDmId" : this.data.chartData.selectedDataModel,

				size: this.params.size,
				name: this.params.name,
				total: this.params.total,
				hash: this.params.hash
			};

			if (this.data.chartData.QAProcess !== undefined) {
				uploadCompleteParams.QAProcess = this.data.chartData.QAProcess
			}
			if (this.data.chartData.disableDuplicateCheck) {
				uploadCompleteParams.disableDuplicateCheck = this.data.chartData.disableDuplicateCheck
			}
			if (this.data.chartData.uploadFields?.length) {
				let saveToDocument = []
				this.data.chartData.uploadFields.forEach((i, index) => {
					if (i.sendOnlyOptionValue && i.type === 'select') {
						uploadCompleteParams[i.name] = uploadFields[index]?.value
					} else {
						uploadCompleteParams[i.name] = uploadFields[index]
					}
					saveToDocument.push(i.name)
				})
				uploadCompleteParams.saveToDocument = saveToDocument
			}
			if (this.data.chartData.modelDataType) {
				uploadCompleteParams.modelDataType = this.data.chartData.modelDataType
			}
			uploadCompleteParams.uploadFields = this.data.chartData.uploadFields
			uploadCompleteParams.uploadBatchUniqueId = this.uploadId

			setTimeout(async () => {
				try {
					const result = await this.bulkUploadPDFFinal(uploadCompleteParams);
					if (result && result.error === '0') {
						this.triggerUploadResult(this.params.index, true);
					}
					else {
						this.triggerFileUploadProgress(false);
					}
				} catch (error) {
					this.triggerFileUploadProgress(false);
					console.error("Error merge in API call:", error);
				}
			}, 5000);

			this.notifyProgress({
				loaded: this.bytesUploadedSoFar,
				total: this.totalBytes,
				part: '0',
				Key: this.params.key,
				Bucket: this.params.Bucket,
			});
			// result = await this.client.send(new CompleteMultipartUploadCommand(uploadCompleteParams));
		} else {
			result = this.putResponse;
		}
		console.log("result ===", result)
		return result
	}

	async createMultipartUpload() {
		if (!this.createMultiPartPromise) {
			this.createMultiPartPromise = Utils.generateGUID()
		}
		this.uploadId = this.createMultiPartPromise;
		console.log('this.uploadId', this.uploadId)
	}

	on(event = "httpUploadProgress", listener) {
		this.uploadEvent = event;
		super.on(event, listener);
	}

	onTotalParts(event = "totalPartsOfChunk", listener) {
		this.totalPartsEvent = event;
		super.on(event, listener);
	}

	async uploadUsingPut(dataPart) {
		// console.log('uploadUsingPut', dataPart)
		// console.log('this.params.body',this.params.body)
		this.isMultiPart = false;
		// const params = { ...this.params, Body: dataPart.data };
		// dataPart.index = dataPart.partNumber
		// dataPart.total = this.params.total
		// dataPart.size = this.params.size
		// dataPart.hash = this.params.hash
		dataPart.name = this.params.name
		// dataPart.appId = this.appId
		// dataPart.dataModelId = this.data.chartData.selectedDataModel
		// try {
		// 	dataPart.data = new Blob([dataPart.data])					
		// } catch (error) {
		// 	console.log("error  ==== ? ", error);
		// }

		//TODO: - data(dataModel,Id)
		dataPart.uniqId = this.uploadId
		dataPart.appId = this.appId
		dataPart.dataModelId = this.isDataModelEmpty ? "noDmId" : this.data.chartData.selectedDataModel
		this.totalParts({
			'totalPartNumber': 1,
			'name': dataPart.name
		})
		this.notifyProgress({ // to show 50% progress in normal upload
			loaded: 100,
			total: 100,
			part: 1,
			Key: this.params.key,
			Bucket: this.params.Bucket,
			"TotalParts": 1
		});

		await this.uploadPDF({
			pdf: this.params.body,
			appId: this.appId,
			dataModelId: this.isDataModelEmpty ? "noDmId" : this.data.chartData.selectedDataModel
		})
		// await this.bulkUploadAPi(dataPart) doing normal upload for small files

		// const putResult = await this.client.send(new PutObjectCommand(params));
		// this.putResponse = putResult;
		const totalSize = BulkUploadUtils.byteLength(dataPart.data);

		this.notifyProgress({
			loaded: 100,
			total: 100,
			part: 1,
			Key: this.params.key,
			Bucket: this.params.Bucket,
			"TotalParts": 1
		}); // to show 100% progress in normal upload
	}


	notifyProgress(progress) {
		if (this.uploadEvent) {
			this.emit(this.uploadEvent, progress);
		}
	}

	totalParts(partNumber) {
		if (this.totalPartsEvent) {
			this.emit(this.totalPartsEvent, partNumber);
		}
	}

	async abortTimeout(abortSignal) {
		return new Promise((resolve, reject) => {
			abortSignal.onabort = () => {
				const abortError = new Error("Upload aborted.");
				abortError.name = "AbortError";
				reject(abortError);
			};
		});
	}

	validateInput() {
		if (!this.params) {
			throw new Error(`InputError: Upload requires params to be passed to upload.`);
		}


		if (this.partSize < MIN_PART_SIZE) {
			throw new Error(
				`EntityTooSmall: Your proposed upload partSize [${this.partSize}] is smaller than the minimum allowed size [${MIN_PART_SIZE}] (5MB)`
			);
		}

		if (this.queueSize < 1) {
			throw new Error(`Queue size: Must have at least one uploading queue.`);
		}
	}
}
