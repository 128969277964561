import { ActionTypes } from 'constants/index';

const initialState = {
    isAuthenticated: false,
    userDetailsLoading: false,
    ssoExpired: false
};

const user = function (state = initialState, action) {
    switch (action.type) {
        case ActionTypes.SET_USER_DATA:
            {
                return {
                    ...initialState,
                    ...action.payload,
                    isAuthenticated: true
                };
            }
        case ActionTypes.REMOVE_USER_DATA:
            {
                return {
                    ...initialState
                };
            }
        case ActionTypes.SET_ORG_ID_TO_USER_OBJ: {
            return {
                ...state,
                orgId: action.payload,
            };
        }
        case ActionTypes.USER_LOGGED_OUT: {
            return initialState;
        }
        case ActionTypes.SET_USER_DETAILS_LOADING: {
            return {
                ...state,
                userDetailsLoading: action.payload,
            };
        }
        case ActionTypes.USER_SSO_EXPIRE: {
            return {
                ...state,
                ssoExpired: true,
            };
        }
        default:
            {
                return state
            }
    }
};

export default user;
